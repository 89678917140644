import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LeaderboardView from '../views/LeaderboardView.vue'
// import GameplanView from '../views/GameplanView.vue'
import GamedayView from '../views/GamedayView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: LeaderboardView
  },
  {
    path: '/gameplan/:month?',
    name: 'Gameplan',
    props: true,
    // component: GameplanView
    component: () =>
      import(/* webpackChunkName: "542e0be529723cc49566dcfd51153057" */ '../views/GameplanView.vue')
  },
  {
    path: '/gameday/:id',
    name: 'Gameday',
    props: true,
    component: GamedayView,
    children: [
      {
        path: 'bet', //:user?&:team?',
        name: 'Bet',
        component: () =>
          import(/* webpackChunkName: "542e0be529723cc49566dcfd51153057" */ '../views/BetView.vue')
      },
      {
        path: 'table',
        name: 'Table',
        component: () =>
          import(
            /* webpackChunkName: "542e0be529723cc49566dcfd51153057" */ '../components/GamedayTable.vue'
          )
      },
      {
        path: 'h2h',
        name: 'H2H',
        component: () =>
          import(
            /* webpackChunkName: "542e0be529723cc49566dcfd51153057" */ '../components/GamedayH2H.vue'
          )
      },
      {
        path: 'statistics',
        name: 'Statistics',
        component: () =>
          import(
            /* webpackChunkName: "542e0be529723cc49566dcfd51153057" */ '../components/GamedayStatistics.vue'
          )
      },
      {
        path: 'info',
        name: 'Info',
        component: () =>
          import(
            /* webpackChunkName: "542e0be529723cc49566dcfd51153057" */ '../components/GamedayInfo.vue'
          )
      }
    ]
  },
  {
    path: '/rules',
    name: 'Rules',
    component: () =>
      import(/* webpackChunkName: "a4f86f7bfc24194b276c22e0ef158197" */ '../views/RulesView.vue')
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () =>
      import(/* webpackChunkName: "a4f86f7bfc24194b276c22e0ef158197" */ '../views/AuthView.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () =>
      import(/* webpackChunkName: "a4f86f7bfc24194b276c22e0ef158197" */ '../views/SignupView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "a4f86f7bfc24194b276c22e0ef158197" */ '../views/LoginView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
