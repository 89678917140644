import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  collection,
  query,
  orderBy,
  where,
  getDocs,
  doc,
  setDoc,
  updateDoc
} from 'firebase/firestore'
// import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'

// import { User, userConverter } from '@/helpers/UserClass'
// import { Statistic, statisticConverter } from '@/helpers/StatisticClass'
// import { Match, matchConverter } from '@/helpers/MatchClass'
// import { getDaysInMonth } from '@/helpers/Date'
import { Bet, betConverter } from '@/helpers/BetClass'
import { User, userConverter } from '@/helpers/UserClass'

import store from '../store'
import router from '../router'

const firebaseConfig = {
  apiKey: 'AIzaSyBngv2oWrjvyR2JMYbDqnOYa-AlKcL2GCM',
  authDomain: 'bet-erci.firebaseapp.com',
  projectId: 'bet-erci',
  storageBucket: 'bet-erci.appspot.com',
  messagingSenderId: '592863262329',
  appId: '1:592863262329:web:12a4e6d7064b6a8f51c6a8',
  measurementId: 'G-BKN5DVQKHB'
}

//const app =
initializeApp(firebaseConfig)
// getAnalytics(app);

const db = getFirestore()

/* AUTHENTICATION */
const auth = getAuth()

function signup(name, email, password, image) {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user
      user.displayName = name
      console.log(user)

      const newUser = new User(user.uid, name, 0, image)
      createUser(newUser)

      store.state.authenticated = true
      router.push({ name: 'Leaderboard' })
    })
    .catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      console.log(errorCode, errorMessage)
      alert(errorMessage)
      // ..
    })
}

/*
var authRef = firebase.auth();
    authRef.onAuthStateChanged(function(user) {
        if (user) {
            console.log('Display name onAuthStateChanged : '+user.displayName);
            _updateUserData();
        } else {
            console.log('not login');
        }
    });

*/

// async function reauthenticate() {
//   const userProvidedPassword = 'Peter Dorneburg'

//   const auth = getAuth()
//   const credential = EmailAuthProvider.credential(auth.currentUser.email, userProvidedPassword)
//   const result = await reauthenticateWithCredential(auth.currentUser, credential)

//   console.log(result)
//   console.log('reauthenticated')
// }

function login(email, password) {
  const auth = getAuth()
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user
      console.log(user)
      store.state.authenticated = true
      // ...
    })
    .catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      console.log(errorCode, errorMessage)
      alert(errorMessage)
    })
}

function logout() {
  const auth = getAuth()
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      store.state.authenticated = false
    })
    .catch((error) => {
      // An error happened.
      console.log(error)
    })
}

async function createUser(user) {
  // name, userid, points
  // const ref = doc(db, 'bets', 'id').withConverter(userConverter)
  // setDoc(ref, user)

  const ref = doc(collection(db, 'users')).withConverter(userConverter)
  await setDoc(ref, user)
}

async function getUsers() {
  let users = [User]
  const q = query(collection(db, 'users').withConverter(userConverter), orderBy('points', 'desc'))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    // console.log(doc.id, " => ", doc.data());
    users.push(doc.data())
  })

  return users
}

async function getStatistics() {
  // let statistic = [Statistic]
  // const querySnapshot = await getDocs(collection(db, 'statistic').withConverter(statisticConverter))
  // querySnapshot.forEach((doc) => {
  //   // console.log(doc.id, " => ", doc.data());
  //   statistic.push(doc.data())
  // })
  // return statistic
}

async function getMatches(month, year) {
  // let matches = [Match]
  console.log(month, year)
  // let numberOfDays = getDaysInMonth(month, year)
  // let _month = month < 10 ? '0' + month.toString() : month.toString()
  // let _year = year.toString()
  // let firstDayofMonth = new Date(_year + '-' + _month + '-01')
  // let lastDayofMonth = new Date(_year + '-' + _month + '-' + numberOfDays)
  //   console.log("firstDayofMonth: ", firstDayofMonth);
  //   console.log("lastDayofMonth: ", lastDayofMonth);

  // const q = query(
  //   collection(db, 'matches').withConverter(matchConverter),
  //   where('time', '>=', firstDayofMonth),
  //   where('time', '<=', lastDayofMonth)
  // )
  // const querySnapshot = await getDocs(q)
  // querySnapshot.forEach((doc) => {
  //   // console.log(doc.id, " => ", doc.data());
  //   matches.push(doc.data())
  // })

  // return matches
}

async function getLast5Matches(gameday) {
  // let matches = [Match]
  console.log(gameday)
  // let lastGameday = Number(gameday) - 1
  // let firstGameday = lastGameday - 5 < 0 ? 0 : lastGameday - 5

  // const q = query(
  //   collection(db, 'matches').withConverter(matchConverter),
  //   where('gameday', '<=', lastGameday),
  //   where('gameday', '>=', firstGameday),
  //   orderBy('gameday', 'desc')
  // )
  // const querySnapshot = await getDocs(q)
  // querySnapshot.forEach((doc) => {
  //   matches.push(doc.data())
  // })

  // return matches
}

async function getLastSaisonMatches(gameday) {
  console.log(gameday)
  // let matches = [Match]
  // let lastGameday = Number(gameday) - 1
  // let firstGameday = lastGameday - 6 < 0 ? 0 : lastGameday - 5

  // const q = query(
  //   collection(db, 'saison').withConverter(matchConverter),
  //   where('gameday', '<=', lastGameday),
  //   where('gameday', '>=', firstGameday),
  //   orderBy('gameday', 'desc')
  // )

  // const querySnapshot1 = await getDocs(q)
  // querySnapshot1.forEach((doc) => {
  //   matches.push(doc.data())
  // })

  // return matches
}

async function getBets(gameday) {
  let bets = [Bet]
  // console.log(gameday)
  const q = query(
    collection(db, 'bets').withConverter(betConverter),
    where('gameday', '==', gameday)
  )
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    // console.log(doc.id, " => ", doc.data());
    bets.push(doc.data())
  })

  return bets
}

async function getLast5Bets(gameday, userId) {
  console.log('gameday', gameday, 'userid', userId)
  let bets = [Bet]
  let lastGameday = Number(gameday) - 1
  let userID = Number(userId)
  let firstGameday = lastGameday - 5 < 0 ? 0 : lastGameday - 5

  const q = query(
    collection(db, 'bets').withConverter(betConverter),
    where('userID', '==', userID),
    where('gameday', '<=', lastGameday),
    where('gameday', '>=', firstGameday),
    orderBy('gameday', 'desc')
  )
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    bets.push(doc.data())
  })

  return bets
}

function addBet(bet) {
  const ref = doc(db, 'bets', bet.id).withConverter(betConverter)
  setDoc(ref, bet)
}

function updateUser(user) {
  console.log("updateUser", user)
  const ref = doc(db, 'users', user.id)//.withConverter(userConverter)
  //console.log(ref)
  //setDoc(ref, user)
  updateDoc(ref, {
    points: user.points
  })

}

export {
  signup,
  login,
  logout,
  createUser,
  getUsers,
  getStatistics,
  getMatches,
  getLast5Matches,
  getLastSaisonMatches,
  getBets,
  getLast5Bets,
  addBet,
  updateUser
}
