<template>
  <!-- <div class="card" :style="'background-color: ' + colors[index]"> -->
  <div class="home-content" :style="isUser()">
    <div class="user">
      <h5>{{ position }}.</h5>
      <!-- <img :src="require('../assets/img/users/' + user.name + '.png')" class="user" /> -->
      <img :src="user.image" alt="user" class="user" />
      <h4>{{ user.name }}</h4>
    </div>
    <!-- <div class="stat">
      <p class="points">{{ user.points }} P</p>
      <h1>#{{ position }}</h1>
    </div> -->
    <div class="points">
      <h1>{{ user.points }}</h1>
      <!-- <h7 :style="style">{{ value }}</h7> -->
      <!-- <img :src="require('../assets/img/' + image + '.png')" /> -->
    </div>
    <!-- <p class="points">{{ user.points }} Punkte</p> -->
    <!-- <h1>#{{ position }}</h1> -->
    <!-- <div class="statistics" v-for="(stat, key) in statistic" :key="key">
      <p v-show="stat != '0' && key != 'userId'">{{ stat }}x</p>
      <p v-show="stat != '0' && key != 'userId'" class="factor">
        {{ statIndex(key) }}
      </p>
    </div> -->
  </div>
  <!-- <hr /> -->
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue'
import { Statistic } from '@/helpers/StatisticClass'
import { User } from '@/helpers/UserClass'
import store from '@/store'

export default {
  name: 'home-card',
  props: {
    index: Number,
    user: User,
    statistic: Statistic,
    position: Number
  },
  setup(props) {
    // const colors = [
    //   "#8ca5a5",
    //   "#b3c5a2",
    //   "#c8b095",
    //   "#8da599",
    //   "#b89569",
    //   "#9a9884",
    //   "#8ca5a5",
    //   "#b3c5a2",
    //   "#c8b095",
    //   "#8da599",
    //   "#b89569",
    //   "#9a9884",
    // ];

    const data = reactive({
      value: '',
      image: 'point',
      style: '',
      currentUserId: store.getters.userId
    })
    // let positionChangeValue = ref("");
    // let positionChangeImage = ref("point");
    // let positionChangeStyle = ref("");

    watchEffect(() => {
      // position.currentUserId = store.getters.currentUserId
      // if (props.statistic != undefined) {
      //   let change = props.statistic.lastPosition - props.statistic.currentPosition
      //   if (change == 0) {
      //     position.value = ''
      //     position.image = 'point'
      //     position.style = ''
      //   } else if (change > 0) {
      //     position.value = '+' + change
      //     position.image = 'arrow_up'
      //     position.style = 'color: green;'
      //   } else {
      //     position.value = '' + change
      //     position.image = 'arrow_down'
      //     position.style = 'color: red;'
      //   }
      // }
    })

    function isUser() {
      if (props.user.userId == data.currentUserId) {
        return 'background-color: rgba(12, 63, 82, 0.5); color: whitesmoke;' // box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 0.3);
      }
    }

    function statIndex(key) {
      switch (key) {
        case 'zero':
          return 0
        case 'one':
          return 1
        case 'two':
          return 2
        case 'three':
          return 3
        case 'four':
          return 4
        case 'five':
          return 5
        default:
          break
      }
    }

    return {
      ...toRefs(data),
      // colors,
      statIndex,
      isUser
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.home-content {
  // display: -webkit-inline-box;
  // width: 100%;
  // height: 80px;
  // background: rgb(219, 196, 162);
  // border: 1px solid #383838;
  // border-radius: 5px;
  // margin: 10px 0;
  padding: 10px 15px; //15px 15px;
  color: darkgray; //#2c3e50;
  height: 60px;
  border-bottom: 0.5px solid #40404079;

  div.user {
    display: -webkit-inline-box;
    position: absolute;
    left: 30px; //15px
    font-size: 20px;

    h5 {
      font-weight: 400;
      font-size: 20px;
      // color: rgb(70, 96, 96);
      padding-right: 15px;
      position: relative;
      top: 9px;
    }

    h4 {
      font-size: 20px;
      font-weight: 400;
      margin: 8px 0px 0px 10px;
    }
  }

  div.stat {
    display: -webkit-inline-box;
    padding: 0px 10px;
    display: -webkit-right;
    // position: absolute;
    // right: 10px;
  }

  img.user {
    width: 40px;
    height: 40px;
    border-radius: 50%; //8px;
  }

  // p {
  //   // position: relative;
  //   margin-block-start: 0;
  //   margin-block-end: 0;
  //   &.points {
  //     // bottom: 85px;
  //     // left: 180px;
  //     // width: -webkit-fit-content;
  //     font-size: 20px;
  //     font-weight: 500;
  //     padding-right: 10px;
  //   }
  // }

  div.points {
    position: absolute;
    right: -10px;

    h1 {
      // position: relative;
      // margin-block-start: 0;
      // margin-block-end: 0;
      font-size: 23px;
      // font-weight: 400;
      // text-align: -webkit-right;
      // bottom: 90px;
      // color: goldenrod;

      position: relative;
      right: 44px;
      top: 7px;
      text-align: end;
    }

    h7 {
      position: relative;
      right: -2px;
      // color: red;
      top: -32px;
    }

    img {
      width: 18px;
      height: 33px;
      position: relative;
      top: -32px;
    }
  }

  // .statistics {
  //   color: darkslategray;
  //   position: relative;
  //   left: 70px;
  //   bottom: 100px;
  //   display: -webkit-inline-flex;
  //   padding-right: 3px;

  //   p.factor {
  //     padding: 0 5px;
  //     margin: 0 2px;
  //     border-radius: 4px;
  //     background-color: $highlight-bg-color; //lightgray;
  //     color: lightgray; //#1a2421
  //   }
  // }

  // hr {
  //   margin-block-start: 0px;
  //   margin: 0;
  // }
}
</style>
