function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate()
}

function getMonthAndYear(month) {
  // let now = new Date()
  let currentYear = 2024 //now.getFullYear()
  let nextYear = 2025 //date.addYears(now, 1).getFullYear()

  switch (month) {
    case 'september':
      return { month: 9, year: currentYear }
    case 'oktober':
      return { month: 10, year: currentYear }
    case 'november':
      return { month: 11, year: currentYear }
    case 'dezember':
      return { month: 12, year: currentYear }

    case 'januar':
      return { month: 1, year: nextYear }
    case 'februar':
      return { month: 2, year: nextYear }
    case 'märz':
      return { month: 3, year: nextYear }
    case 'april':
      return { month: 4, year: nextYear }
    default:
      return { month: 9, year: currentYear }
  }
}

function getCurrentMonth() {
  let month = new Date().toLocaleString('de-DE', { month: 'long' }).toLowerCase()
  let excludedMonths = ['mai', 'juni', 'juli', 'august']
  if (excludedMonths.includes(month)) {
    return 'september'
  } else {
    return month
  }
}

import date from 'date-and-time'
import de from 'date-and-time/locale/de'
function getTransformedDate(seconds) {
  date.locale(de)
  return date.format(new Date(seconds * 1000), 'dddd, DD.MM.YYYY – HH:mm')
}

function getShortDate(seconds) {
  date.locale(de)
  return date.format(new Date(seconds * 1000), 'DD.MM.YY')
}

function getStartDate(month) {
  const monthyear = getMonthAndYear(month)

  let _month = monthyear.month < 10 ? '0' + monthyear.month.toString() : monthyear.month.toString()
  let _year = monthyear.year.toString()
  return new Date(_year + '-' + _month + '-01')
}

function getEndDate(month) {
  const monthyear = getMonthAndYear(month)

  let numberOfDays = getDaysInMonth(monthyear.month, monthyear.year)
  let _month = monthyear.month < 10 ? '0' + monthyear.month.toString() : monthyear.month.toString()
  let _year = monthyear.year.toString()
  return new Date(_year + '-' + _month + '-' + numberOfDays)
}

export {
  getDaysInMonth,
  getMonthAndYear,
  getCurrentMonth,
  getTransformedDate,
  getShortDate,
  getStartDate,
  getEndDate
}
