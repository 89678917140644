<template>
  <b-card no-body>
    <b-card-text>
      <slot></slot>
    </b-card-text>
  </b-card>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.card {
  background-color: $background-color; //#141b19
  overflow-y: auto;
  overflow-x: hidden;
  // padding-top: 15px;
  //border: 1px solid $navigation-color; //#242424
  border: none;
  border-radius: 0px; //5px

  &::-webkit-scrollbar {
    display: none;
  }

  .card-text {
    background-color: $card-bg-color;
  }
}
</style>
