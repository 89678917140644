import { createStore } from 'vuex'
import {
  getUsers,
  // getStatistics,
  // getMatches,
  // getLast5Matches,
  // getLastSaisonMatches,
  getBets,
  // getLast5Bets,
  addBet,
  updateUser
} from '../server/firebase.js'

import { fetchGames, fetchH2H, fetchTable, fetchStatistcs } from '../server/rapid.js'

export default createStore({
  state: {
    title: 'Rangliste',
    showTabbar: true,
    showBackButton: false,
    month: 'september',
    users: [],
    currentUserId: '',
    statistics: [],
    matches: [],
    table: [],
    h2h: [],
    // lastMatches: [],
    bets: [],
    // last5Bets: [],
    authenticated: false
  },
  getters: {
    title(state) {
      return state.title
    },
    showTabbar(state) {
      return state.showTabbar
    },
    showBackButton(state) {
      return state.showBackButton
    },
    month(state) {
      return state.month
    },
    users(state) {
      return state.users
    },
    userId(state) {
      return state.currentUserId
    },
    statistics(state) {
      return state.statistics
    },
    matches(state) {
      return state.matches
    },
    table(state) {
      return state.table
    },
    h2h(state) {
      return state.h2h
    },
    lastMatches(state) {
      return state.lastMatches
    },
    bets(state) {
      return state.bets
    },
    last5Bets(state) {
      return state.last5Bets
    },
    authenticated(state) {
      return state.authenticated
    }
  },
  mutations: {
    SET_TITLE(state, title) {
      state.title = title
    },
    SHOW_TABBAR(state, show) {
      state.showTabbar = show
    },
    SHOW_BACK_BUTTON(state, show) {
      state.showBackButton = show
    },
    SET_MONTH(state, month) {
      state.month = month
    },
    SET_USERS(state, users) {
      state.users.length = 0
      users.shift()
      state.users = users
    },
    SET_STATISTICS(state, statistics) {
      statistics.shift()
      state.statistics = statistics
    },
    // SET_MATCHES(state, matches) {
    //   state.matches.length = 0
    //   matches.shift()
    //   state.matches = matches
    // },
    SET_API_MATCHES(state, matches) {
      state.matches = matches
    },
    SET_API_TABLE(state, table) {
      state.table = table
    },
    SET_API_H2H(state, h2h) {
      state.h2h = h2h
    },
    SET_API_STATISTICS(state, statistics) {
      // state.statistics = statistics
      state.statistics.push(statistics)
    },
    // SET_LAST_5_MATCHES(state, matches) {
    //   state.lastMatches.length = 0
    //   matches.shift()
    //   state.lastMatches = matches
    // },
    // SET_LAST_SAISON_MATCHES(state, matches) {
    //   // state.lastSaisonMatches.length = 0;
    //   matches.shift()

    //   matches.forEach((el) => {
    //     state.lastMatches.push(el)
    //   })
    //   state.lastMatches.sort(function (a, b) {
    //     return a.gameday < b.gameday
    //   })

    //   console.log('STORE', state.lastMatches)
    // },
    SET_BETS(state, bets) {
      state.bets.length = 0
      bets.shift()
      state.bets = bets
    },
    // SET_LAST_5_BETS(state, bets) {
    //   state.last5Bets.length = 0
    //   bets.shift()
    //   state.last5Bets = bets
    // },
    ADD_BET(state, bet) {
      let exist = state.bets.find((el) => el.id == bet.id)
      if (exist != null) {
        exist.home = bet.home
        exist.away = bet.away
        exist.ending = bet.ending
      } else {
        state.bets.push(bet)
      }
    },
    UPDATE_USER(state, user) {
      let exist = state.users.find((el) => el.userId == user.userId)
      if (exist != null) {
        exist.points = user.points
      } else {
        state.bets.push(user)
      }
    }
  },
  actions: {
    setTitle({ commit }, title) {
      commit('SET_TITLE', title)
    },
    showTabbar({ commit }, show) {
      commit('SHOW_TABBAR', show)
    },
    showBackButton({ commit }, show) {
      commit('SHOW_BACK_BUTTON', show)
    },
    setMonth({ commit }, month) {
      commit('SET_MONTH', month)
    },
    async fetchUsers({ commit }) {
      let users = await getUsers()
      commit('SET_USERS', users)
    },
    // async fetchStatistics({ commit }) {
    //   let statistics = await getStatistics()
    //   commit('SET_STATISTICS', statistics)
    // },
    async fetchGames({ commit }) {
      let matches = await fetchGames()
      commit('SET_API_MATCHES', matches)
    },
    async fetchTable({ commit }) {
      let table = await fetchTable()
      commit('SET_API_TABLE', table)
    },
    async fetchH2H({ commit }, { team1Id, team2Id }) {
      let h2h = await fetchH2H(team1Id, team2Id)
      commit('SET_API_H2H', h2h)
    },
    async fetchStatistics({ commit }, { teamId }) {
      let statistics = await fetchStatistcs(teamId)
      commit('SET_API_STATISTICS', statistics)
    },
    // async fetchMatches({ commit }, data) {
    //   let matches = await getMatches(data.month, data.year)
    //   commit('SET_MATCHES', matches)
    // },
    // async fetchLast5Matches({ commit }, gameday) {
    //   let matches = await getLast5Matches(gameday)
    //   commit('SET_LAST_5_MATCHES', matches)
    // },
    // async fetchLastSaisonMatches({ commit }, gameday) {
    //   let matches = await getLastSaisonMatches(gameday)
    //   commit('SET_LAST_SAISON_MATCHES', matches)
    // },
    async fetchBets({ commit }, gameday) {
      let bets = await getBets(parseInt(gameday))
      commit('SET_BETS', bets)
    },
    // async fetchLast5Bets({ commit }, { gameday, userId }) {
    //   let bets = await getLast5Bets(gameday, userId)
    //   commit('SET_LAST_5_BETS', bets)
    // },
    addBet({ commit }, bet) {
      addBet(bet)
      commit('ADD_BET', bet)
    },

    updateUser({ commit }, user) {
      updateUser(user)
      commit('UPDATE_USER', user)
    }
  },
  modules: {}
})
