<template>
  <nav>
    <transition name="fade" duration="50">
      <fa-icon icon="left-long" class="fa-lg back" v-show="show" @mousedown="goBack()" />
    </transition>
    <h3>{{ title }}</h3>
    <fa-icon icon="arrow-right-from-bracket" class="fa-lg logout" @mousedown="logout()" />

    <div v-show="gameplanRoute" class="navigation">
      <Link v-for="(month, key) in gameMonths" :key="key" :class="month" :month="month">{{
        month
      }}</Link>
    </div>
  </nav>
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue'
import store from '@/store'
import router from '@/router'
import Link from '@/components/NavbarLink.vue'
import { logout } from '@/server/firebase'

export default {
  name: 'nav-bar',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Link
  },
  setup() {
    const state = reactive({
      title: store.getters.title,
      show: store.getters.showBackButton,
      gameplanRoute: false,
      gameMonths: [
        'September',
        'Oktober',
        'November',
        'Dezember',
        'Januar',
        'Februar',
        'März',
        'April'
      ]
    })

    watchEffect(() => {
      state.title = store.getters.title
      state.show = store.getters.showBackButton
      state.gameplanRoute = router.currentRoute.value.name == 'Gameplan'
    })

    function goBack() {
      // router.push('/gameplan')
      router.push({ name: 'Gameplan', params: { month: store.getters.month } })
      // if (!router.currentRoute.value.params.user) {
      //   router.go(-1)
      // } else {
      //   router.go(-2)
      // }
      store.dispatch('showBackButton', false)
      store.dispatch('showTabbar', true)
    }

    return {
      ...toRefs(state),
      goBack,
      logout
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.back {
  position: absolute;
  left: 10px;
  top: 12px;
  padding: 10px;
}
.logout {
  position: absolute;
  right: 10px;
  top: 12px;
  padding: 10px;
}
div.navigation {
  // padding: 0px 10px 0px 10px;
  // text-indent: 10px;
  text-align: -webkit-center;
  // margin: 0px -10px;
  display: -webkit-inline-flex;
  overflow-x: scroll;
  overflow-y: hidden;
  // overflow: auto;
  position: sticky;
  top: 0;
  width: 100vw;
  // background: #100c08;
  background: $navigation-color; //#1a2421
  z-index: 900;
  // border-bottom: 0.3px solid black;
  @media only screen and (min-width: 580px) {
    justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  // a {
  //   color: #f5f5f5;
  //   padding-right: 10px;
  //   text-decoration: none;
  //   font-size: 15px;

  //   &.router-link-exact-active {
  //     color: darkgoldenrod;
  //     font-weight: 600;
  //     text-decoration: underline;

  //     .indicator {
  //       display: block;
  //       background-color: darkgoldenrod;
  //     }
  //   }
  // }
}
</style>
