class Statistic {
  constructor(zero, one, two, three, four, five, lastPosition, currentPosition, userId) {
    this.zero = zero
    this.one = one
    this.two = two
    this.three = three
    this.four = four
    this.five = five
    this.lastPosition = lastPosition
    this.currentPosition = currentPosition
    this.userId = userId
  }
}

const statisticConverter = {
  toFirestore: (statistic) => {
    return {
      1: statistic.one,
      2: statistic.two,
      3: statistic.three,
      4: statistic.four,
      5: statistic.five,
      lastPosition: statistic.lastPosition,
      currentPosition: statistic.currentPosition,
      userID: statistic.userID
    }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return new Statistic(
      data[`0`],
      data[`1`],
      data[`2`],
      data[`3`],
      data[`4`],
      data[`5`],
      data.lastPosition,
      data.currentPosition,
      data.userID
    )
  }
}

export { Statistic, statisticConverter }
