<template>
  <div id="gameday">
    <!-- <ListHeader :gameday="match.gameday" /> -->

    <ListHeader>
      <div class="info">
        <h6>{{ title }}</h6>
        <p>{{ time }}</p>
      </div>
    </ListHeader>
    <!-- <ListHeader :gameday="match.week" /> -->
    <div class="match">
      <!-- <h3>{{ title }}</h3> -->
      <!-- <h5>{{ new Date(match.time.seconds * 1000) }}</h5> -->
      <!-- <h5>{{ time }}</h5> -->
      <div class="inline">
        <img :src="homeTeamImage" />
        <div style="display: block">
          <h4 :style="scoreStyle">{{ score }}</h4>
          <span>{{ getPeriods(match) }}</span>
        </div>
        <img :src="awayTeamImage" />
      </div>
    </div>
    <div class="navigation">
      <div class="content">
        <GamedayLink v-for="(item, key) in menuItems" :key="key" :class="item" :item="item">{{
          item
        }}</GamedayLink>
      </div>
    </div>

    <!-- <ListHeader :gameday="match.gameday" /> -->
    <!-- <div class="list" v-if="!$route.params.user">
      <ListCard>
        <ListUserCell
          v-for="(user, key) in users"
          :key="key"
          :user="user"
          :time="match.time.seconds"
          :gameday="match.gameday"
          :last="key == users.length - 1 ? false : true"
        />
      </ListCard>
    </div> -->
    <!-- <router-view></router-view> -->
    <!-- <router-view v-else></router-view> -->

    <router-view></router-view>
  </div>
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue'
import ListHeader from '@/components/ListHeader.vue'
import store from '@/store'
import { getTransformedDate } from '@/helpers/Date.js'
import { getScore, teamLongName, getPeriods } from '../helpers/MatchHelper'
import GamedayLink from '@/components/GamedayLink.vue'
import router from '@/router'

export default {
  props: ['id'],
  beforeRouteEnter() {
    // store.dispatch('fetchBets', props.params.id)
  },

  setup(props) {
    // router.push({ name: 'Bet', params: { id: props.id } })
    if (router.currentRoute.name != 'Bet') {
      router.push({ name: 'Bet', params: { id: props.id } })
    }

    const state = reactive({
      // match: store.getters.matches.find((el) => el.gameday == props.id),
      users: store.getters.users,
      homeTeamImage: URL,
      awayTeamImage: URL
    })

    let match = store.getters.matches.find((el) => el.id == props.id)
    const title = teamLongName(match.teams.home.name) + ' vs ' + teamLongName(match.teams.away.name)
    const score = getScore(match)
    const scoreStyle = score != '–:–' ? 'color: darkgoldenrod;' : 'color: gray; margin-top: 20px;'
    const time = getTransformedDate(match.timestamp)
    const menuItems = ['Tippen', 'H2H', 'Tabelle'] //['Tippen', 'H2H', 'Statistiken', 'Spielinfo', 'Tabelle']

    // import(
    //   /* @vite-ignore */
    //   `../assets/img/teams/${teamLongName(match.teams.home.name)}.png`
    // ).then((result) => {
    //   state.homeTeamImage = result.default
    // })

    state.homeTeamImage = require(`../assets/img/teams/${teamLongName(match.teams.home.name)}.png`)
    state.awayTeamImage = require(`../assets/img/teams/${teamLongName(match.teams.away.name)}.png`)

    // import(
    //   /* @vite-ignore */
    //   `../assets/img/teams/${teamLongName(match.teams.away.name)}.png`
    // ).then((result) => {
    //   state.awayTeamImage = result.default
    // })

    watchEffect(() => {
      state.bets = store.getters.bets
    })

    return {
      ...toRefs(state),
      match,
      title,
      score,
      scoreStyle,
      time,
      menuItems,
      getPeriods
    }
  },
  components: { ListHeader, GamedayLink }
}
</script>

<style lang="scss" scoped>
@import '../assets/style/variables.scss';

#gameday {
  overflow: hidden;
  .match {
    background-color: $highlight-bg-color; //#141b19
    // margin: 0px -10px;
    position: sticky;
    top: 0px;
    z-index: 200;

    h3 {
      padding: 10px;
      font-size: 15px;
      margin-block-start: 0;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
        Open Sans, Helvetica Neue, sans-serif;
      //   color: #d3d3d3;
      color: #f5f5f5;
    }

    h4 {
      margin-top: 10px;
      margin-block-end: 0;
      padding: 0 40px;
      font-size: 2rem;
      font-weight: 500;
      font-family: Lato, sans-serif;
      color: #f5f5f5;
    }

    // h5 {
    //   margin-block-end: 0;
    //   margin: -10px 0 0 0;
    //   //   color: #f5f5f5;
    //   color: #d3d3d3;
    //   display: block;
    //   font-size: 0.83em;
    //   //   font-weight: bold;
    // }

    .inline {
      display: inline-flex;
      padding: 20px 0 20px 0;
    }

    img {
      width: 80px;
      height: 80px;
    }
  }

  span {
    font-size: 12px;
    color: darkgrey;
  }

  div.list {
    // padding: 20px 0px;
    overflow-y: auto;
    margin-top: -1px;
  }

  div.navigation {
    text-align: -webkit-center;
    // display: -webkit-inline-flex;
    overflow-x: scroll;
    overflow-y: hidden;
    position: sticky;
    top: 0;
    width: 100vw;
    background: $navigation-color; //#1a2421
    border-top: 1px solid #141b19;
    padding-top: 5px;
    z-index: 900;
    // @media only screen and (min-width: 580px) {
    // justify-content: center;
    // }
    .content {
      display: inline-flex;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  div.list-header {
    position: sticky;
    top: 0px; //155px
    z-index: 100;
  }

  div.info {
    color: #a9a9a9;
    padding: 5px 0px;
    text-align: center;
    text-indent: 15px;

    p {
      // color: #a9a9a9;
      -webkit-margin-before: 1px;
      margin-block-start: 1px;
      -webkit-margin-after: 1px;
      margin-block-end: 1px;
      font-size: 12px;
      font-weight: 500;
      // padding: 0px 0px 5px 15px;
    }
  }
}
</style>
