class User {
  constructor(id, userId, name, points, image) {
    this.id = id
    this.userId = userId
    this.name = name
    this.points = points
    this.image = image
  }
}

const userConverter = {
  toFirestore: (user) => {
    return {
      userId: user.userId,
      name: user.name,
      points: user.points,
      image: user.image
    }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return new User(snapshot.id, data.userId, data.name, data.points, data.image)
  }
}

export { User, userConverter }
