<script setup>
// import AuthView from './views/AuthView.vue';

// import { getAuth } from "firebase/auth"
// import { ref } from 'vue'
import { getAuth } from 'firebase/auth'
import store from './store'
import router from './router'
// import { User } from '@/helpers/UserClass'
// import { createUser } from './server/firebase';
// import { User } from './helpers/UserClass';

// if(store.getters.authenticated) {
//   console.log("reauthenticated in App.vue")
//   reauthenticate()
// }
// store.getters.authenticated = false

getAuth().onAuthStateChanged(function (user) {
  if (user) {

    store.state.authenticated = true
    store.state.currentUserId = user.uid

    router.push('/leaderboard')
  } else {

    let currentRoute = router.currentRoute.value.name
    if (currentRoute != 'Login' && currentRoute != 'Signup') {
      router.push('/auth')
    }
  }
})
</script>

<template>
  <div v-if="!store.getters.authenticated">
    <!-- <AuthView /> -->
    <router-view />
  </div>
  <div v-else>
    <!-- <h1>{{ userCredential }}</h1> -->
    <nav-bar />
    <content>
      <!-- <button @click="createUser(new User('id', 'nickname', 0))">Create User</button> -->
      <router-view />
    </content>
    <tab-bar />
  </div>
</template>
