<template>
  <div>
    <!-- <indicator /> -->
    <!-- <router-link :to="{ name: route.name, params: { month: route.params } }"> -->
    <!-- <router-link :to="setRoute()"> -->
    <div class="indicator" />
    <fa-icon :icon="icon" />
    <!-- Home -->
    <!-- </router-link> -->
  </div>
</template>

<script>
export default {
  name: 'tab-bar-button',
  props: {
    icon: String
  }
}
</script>

<style lang="scss" scoped>
div.indicator {
  display: block;
  width: 55px; //60px
  height: 4px;
  border-radius: 5px;
  background: none;
}

svg {
  margin-top: 12px;
  font-size: 25px;
}

a {
  color: silver;
  text-decoration: none;
  display: inline-block;
  &.router-link-active {
    color: darkgoldenrod;

    .indicator {
      display: block;
      background-color: darkgoldenrod;
    }
  }
}
</style>
