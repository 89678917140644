<template>
  <div class="list-header">
    <slot>
      <hr />
      <div class="inline">
        <img src="../assets/img/penny-del-logo.png" width="28" height="15" />
        <p>{{ kind }} – {{ gameday }}. Spieltag</p>
      </div>
      <hr />
    </slot>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
  props: {
    gameday: Number
  },
  setup(props) {
    const state = reactive({
      count: 0,
      kind: props.gameday <= '60' ? 'Hauptrunde' : 'Playoffs'
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

div.list-header {
  background-color: $highlight-bg-color; //#141b19;
  border: 0.3px solid #141b19;
  margin: 0px -10px;

  .inline {
    display: -webkit-inline-flex;
    align-items: flex-center;
    padding: 5px 0px;
  }

  // position: -webkit-sticky;
  // top: 0;

  // &:first-child {
  //   border-top-left-radius: 3px;
  //   border-top-right-radius: 3px;
  // }

  // &:last-child {
  //   border-bottom-left-radius: 3px;
  //   border-bottom-right-radius: 3px;
  // }

  p {
    color: #a9a9a9;
    -webkit-margin-before: 1px;
    margin-block-start: 1px;
    -webkit-margin-after: 1px;
    margin-block-end: 1px;
    text-align: -webkit-left;
    text-indent: 10px;
    font-size: 12px;
    font-weight: 500;
  }

  img {
    margin-top: 2px;
  }

  hr {
    margin-block-start: -1px;
    margin-block-end: 0px;
  }
}
</style>
