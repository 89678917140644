import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import './assets/style/index.scss'

import Navbar from './components/Navbar.vue'
import Tabbar from './components/Tabbar.vue'
import ListCard from './components/ListCard.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTrophy,
  faBuildingColumns,
  faShield,
  faLeftLong,
  faEllipsis,
  faPieChart,
  faCheck,
  faArrowRotateForward,
  faMars,
  faVenus,
  faArrowRightFromBracket
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faTrophy)
library.add(faBuildingColumns)
library.add(faShield)
library.add(faLeftLong)
library.add(faEllipsis)
library.add(faPieChart)
library.add(faCheck)
library.add(faArrowRotateForward)
library.add(faMars)
library.add(faVenus)
library.add(faArrowRightFromBracket)

const app = createApp(App)
app.use(store).use(router).use(BootstrapVue3)

app.component('nav-bar', Navbar)
app.component('tab-bar', Tabbar)
app.component('list-card', ListCard)
app.component('fa-icon', FontAwesomeIcon)

app.mount('#app')
