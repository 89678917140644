<template>
  <div class="link">
    <router-link :class="month" :to="{ name: 'Gameplan', params: { month: month.toLowerCase() } }"
      >{{ month }}
      <div class="indicator" :style="calcWidth()" />
    </router-link>
  </div>
</template>

<script>
// import { reactive, toRefs } from "vue";

export default {
  props: {
    month: String
  },
  setup(props) {
    function calcWidth() {
      let width = props.month.length * 0.7
      return 'width: ' + width + ';'
    }

    return { calcWidth }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';
.link {
  // display: inline-table;
  padding: 0px 5px 0px 5px;
  // text-indent: 10px;
  // margin: 0px -5px;
  // overflow: auto;
  // position: sticky;
  // top: 0;
  // background: #100c08;
  height: 33px;
  background: $navigation-color; //#1a2421
  z-index: 900;
  // border-bottom: 0.3px solid black;

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    color: #f5f5f5;
    padding-right: 10px;
    text-decoration: none;
    font-size: 16px; //15px

    &.router-link-exact-active {
      color: darkgoldenrod;
      font-weight: 600;
      text-decoration: none; //underline

      .indicator {
        display: block;
        background-color: darkgoldenrod;
      }
    }
  }
}

div.indicator {
  display: block;
  //width: 50px; //60px
  height: 4px;
  border-radius: 5px;
  background: none;
  // margin-left: 10px;
  // position: relative;
  // left: 10px;
  margin-top: 5px;
}
</style>
