import axios from 'axios'

const config = {
  method: 'get',
  headers: {
    'x-rapidapi-key': '5115eab581cd7ddf49d11d80838a62ca',
    'x-rapidapi-host': 'v1.hockey.api-sports.io'
  }
}

async function fetchGames() {
  config.url = 'https://v1.hockey.api-sports.io/games?league=19&season=2024&team=247' //'https://v1.hockey.api-sports.io/games?league=19&season=2023&team=247'
  try {
    const response = await axios(config)
    console.log(response, response.data.response)
    return response.data.response
  } catch (error) {
    console.log(error)
  }
}

async function fetchTable() {
  config.url = 'https://v1.hockey.api-sports.io/standings?league=19&season=2024'
  try {
    const response = await axios(config)
    return response.data.response[0]
  } catch (error) {
    console.log(error)
  }
}

async function fetchH2H(team1Id, team2Id) {
  config.url = 'https://v1.hockey.api-sports.io/games/h2h?league=19&h2h=' + team1Id + '-' + team2Id
  try {
    const response = await axios(config)
    // console.log(response.data.response)
    return response.data.response
  } catch (error) {
    console.log(error)
  }
}

async function fetchStatistcs(teamId) {
  config.url =
    'https://v1.hockey.api-sports.io/teams/statistics?league=19&season=2024&team=' + teamId
  try {
    const response = await axios(config)
    return response.data.response
  } catch (error) {
    console.log(error)
  }
}

export { fetchGames, fetchTable, fetchH2H, fetchStatistcs }
