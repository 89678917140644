function teamLongName(team) {
  switch (team) {
    case 'Adler Mannheim':
      return 'Adler Mannheim'
    case 'Augsburger Panther':
      return 'Augsburger Panther'
    case 'Bietigheim/Bissingen':
      return 'Bietigheim Steelers'
    case 'Dusseldorf':
      return 'Düsseldorfer EG'
    case 'Eisbaren Berlin':
      return 'Eisbären Berlin'
    case 'ERC Ingolstadt':
      return 'ERC Ingolstadt'
    case 'Bremerhaven':
      return 'Fischtown Pinguins'
    case 'Grizzly Wolfsburg':
      return 'Grizzlys Wolfsburg'
    case 'Iserlohn Roosters':
      return 'Iserlohn Roosters'
    case 'Kolner':
      return 'Kölner Haie'
    case 'Krefeld Pinguine':
      return 'Krefeld Pinguine'
    case 'Frankfurt Lowen':
      return 'Löwen Frankfurt'
    case 'Nurnberg Ice Tigers':
      return 'Nürnberg Ice Tigers'
    case 'Munchen':
      return 'Red Bull München'
    case 'Schwenninger':
      return 'Schwenninger Wild Wings'
    case 'Straubing Tigers':
      return 'Straubing Tigers'
    default:
      break
  }
}

function teamShortName(team) {
  switch (team) {
    case 'Adler Mannheim':
      return 'MAN'
    case 'Augsburger Panther':
      return 'AEV'
    case 'Bietigheim/Bissingen':
      return 'SCB'
    case 'Dusseldorf':
      return 'DEG'
    case 'Eisbaren Berlin':
      return 'EBB'
    case 'ERC Ingolstadt':
      return 'ING'
    case 'Bremerhaven':
      return 'BHV'
    case 'Grizzly Wolfsburg':
      return 'WOB'
    case 'Iserlohn Roosters':
      return 'IEC'
    case 'Kolner':
      return 'KEC'
    case 'Krefeld Pinguine':
      return 'KEV'
    case 'Frankfurt Lowen':
      return 'FRA'
    case 'Nurnberg Ice Tigers':
      return 'NIT'
    case 'Munchen':
      return 'RBM'
    case 'Schwenninger':
      return 'SWW'
    case 'Straubing Tigers':
      return 'STR'
    default:
      break
  }
}

function getStatus(status) {
  switch (status) {
    case 'NS':
      return '–:–'
    case 'POST':
      return ''
    case 'CANC':
      return ''
    case 'INTR':
      return ''
    case 'ABD':
      return ''
    case 'AOT':
      return 'OT'
    case 'AP':
      return 'SO'
    case 'FT':
      return ''
    default:
      return 'Live'
  }
  /*

    Available status

    NS : Not Started
    P1 : First Period (In Play)
    P2 : Second Period (In Play)
    P3 : Third Period (In Play)
    OT : Over Time (In Play)
    PT : Penalties Time (In Play)
    BT : Break Time (In Play)
    AW : Awarded
    POST : Postponed
    CANC : Cancelled
    INTR : Interrupted
    ABD : Abandoned
    AOT : After Over Time (Game Finished)
    AP : After Penalties (Game Finished)
    FT : Finished (Game Finished)
    
    */
}

function getScore(match) {
  let status = getStatus(match.status.short)
  switch (match.status.short) {
    case 'AOT':
    case 'AP':
    case 'FT':
      return match.scores.home + ':' + match.scores.away + ' ' + status
    default:
      return status
  }
}

function getWinner(match, kind) {
  if (match.scores.home > match.scores.away) {
    if (kind == 'home') {
      return true
    } else {
      return false
    }
  } else if (match.scores.home < match.scores.away) {
    if (kind == 'home') {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

function getPeriods(match) {
  const first = match.periods.first
  const second = match.periods.second != null ? ' | ' + match.periods.second : ''
  const third = match.periods.third != null ? ' | ' + match.periods.third : ''
  const overtime = match.periods.overtime != null ? ' | ' + match.periods.overtime : ''
  const penalties = match.periods.penalties != null ? ' | ' + match.periods.penalties : ''

  if (first === null) {
    return ''
  }

  switch (match.status.short) {
    case 'AOT':
    case 'AP':
    case 'FT':
      return '( ' + first + second + third + overtime + penalties + ' )'
    default:
      return ''
  }
}

import { getStartDate, getEndDate } from '../helpers/Date'
function getMatchesInMonth(month, matches) {
  let startDate = getStartDate(month)
  let endDate = getEndDate(month)
  let newMatches = []

  if (matches == null) {
    return []
  }

  matches.forEach((match) => {
    let matchDate = new Date(match.date)
    if (startDate.getTime() <= matchDate.getTime() && matchDate.getTime() <= endDate.getTime()) {
      newMatches.push(match)
    }
  })

  return newMatches
}

function getMatchesInSeason(season, matches) {
  if (matches == null) {
    return []
  }

  let newMatches = []
  matches.forEach((match) => {
    if (match.league.season == season) {
      if (match.scores.home != null) {
        newMatches.push(match)
      }
    }
  })

  newMatches.sort((el1, el2) => el1.timestamp < el2.timestamp)

  return newMatches
}

export {
  teamLongName,
  teamShortName,
  getStatus,
  getScore,
  getWinner,
  getPeriods,
  getMatchesInMonth,
  getMatchesInSeason
}
