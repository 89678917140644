import { Timestamp } from 'firebase/firestore'

class Bet {
  constructor(id, userId, home, away, ending, gameday, points) {
    this.id = id
    this.userId = userId
    this.home = home
    this.away = away
    this.ending = ending
    this.gameday = gameday
    this.points = points
  }
}

const betConverter = {
  toFirestore: (user) => {
    return {
      userId: user.userId,
      home: user.home,
      away: user.away,
      ending: user.ending,
      gameday: user.gameday,
      points: user.points,
      timestamp: Timestamp.fromDate(new Date())
    }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return new Bet(
      snapshot.id,
      data.userId,
      data.home,
      data.away,
      data.ending,
      data.gameday,
      data.points
    )
  }
}

export { Bet, betConverter }
