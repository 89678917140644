<template>
  <div id="leaderboard">
    <div class="winning">
      <img :src="currentUser.image" class="name" />
      <h2>{{ currentUser.name }}</h2>
      <img :src="require('../assets/img/crown.png')" class="crown" v-show="position == 1" />
      <div class="position">
        <span class="h7">Position</span>
        <h1>{{ position }}</h1>
      </div>
      <div class="points">
        <span class="h7">Punkte</span>
        <h1>{{ currentUser.points }}</h1>
      </div>
    </div>
    <table class="header">
      <tbody>
        <tr>
          <td class="header_user_position"><p>#</p></td>
          <td class="header_user_name"><p>Benutzer</p></td>
          <td><span></span></td>
          <td class="header_user_points"><p>Pkt</p></td>
        </tr>
      </tbody>
    </table>
    <Card v-show="users.length > 1" style="border: none">
      <HomeCard
        v-for="(user, key) in getUsers()"
        :key="key"
        :index="key"
        :user="user"
        :statistic="getUserStatistic(user)"
        :position="getUserPosition(user.userId, key)"
      />
    </Card>
  </div>
  <!-- :position="getUserPosition(key)" -->
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue'
import HomeCard from '@/components/HomeCard.vue'
import Card from '@/components/ListCard.vue'
import store from '@/store'
import { User } from '@/helpers/UserClass'
import { Statistic } from '@/helpers/StatisticClass'
import crown from '@/assets/img/crown.png'
// import axios from "axios"

export default {
  beforeRouteEnter() {
    //if (store.getters.users.length == 0) {
      store.dispatch('fetchUsers')
    //}
    if (store.getters.statistics.length == 0) {
      // store.dispatch('fetchStatistics')
    }
  },
  setup() {
    // let users = ref([User]);
    // users = ref(store.getters.users);

    // if (store.getters.users.length > 0) {
    //   users = [User];
    //   for (let i = 1; i < store.getters.users.lenth; i++) {
    //     console.log("normal: ", store.getters.users[i]);
    //     users[i - 1].value = store.getters.users[i];
    //   }
    // }
    const state = reactive({
      users: [User],
      statistics: [Statistic],
      currentUser: User,
      position: 1
    })
    // let winnerPoints = ref(0);
    // let winnerName = ref("");
    // let winnerImage = ref("");

    // let statistics = ref([Statistic]);
    // statistics = ref(store.getters.statistics);

    watchEffect(() => {
      state.users = store.getters.users
      if (state.users.length > 0) {
        const userId = store.getters.userId
        state.users.forEach((user) => {
          if (user.userId == userId) {
            state.currentUser = user
          }
        })
      }
    })

    function getUserStatistic(user) {
      for (let i = 0; i < state.statistics.length; i++) {
        if (state.statistics[i].userId == user.userId) {
          return state.statistics[i]
        }
      }
    }

    let position = 0
    function getUserPosition(userId, key) {
      if (key == 0) {
        position = 1
      } else {
        if (state.users[key].points != state.users[key - 1].points) {
          position++
        }
      }

      if (state.currentUser.userId == userId) {
        state.position = position
      }

      return position
    }

    function getUsers() {
      if (state.users.length > 0) {
        let restUsers = [User]
        for (let i = 0; i < state.users.length; i++) {
          restUsers[i] = new User(
            state.users[i].id,
            state.users[i].userId,
            state.users[i].name,
            state.users[i].points,
            state.users[i].image
          )
        }
        return restUsers
      }
    }

    return {
      ...toRefs(state),
      getUserStatistic,
      getUserPosition,
      getUsers,
      crown
    }
  },
  components: { Card, HomeCard }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

#leaderboard {
  .card {
    height: calc(100vh - 64px - 180px - 40px - 80px);
  }
  .winning {
    height: 180px;
    background: $navigation-color;
    padding: 25px 15px;
    color: darkgoldenrod;

    h1 {
      font-size: 35px;
    }

    h2 {
      padding-top: 6px;
    }

    img {
      &.name {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        margin-top: 8px;
      }

      &.crown {
        width: 70px;
        height: 40px;
        position: absolute;
        margin-left: -35px;
        top: 5px;
      }
    }
    div.position {
      position: absolute;
      left: 30px;
      top: 50px;
    }

    div.points {
      position: absolute;
      right: 30px;
      top: 50px;
    }
  }

  span.h7 {
    box-sizing: border-box;
    color: rgb(184, 134, 11);
    display: inline;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: 24px;
    text-align: center;
    width: auto;
    -webkit-font-smoothing: antialiased;
  }
}
</style>
