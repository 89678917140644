<template>
  <transition name="fade" mode="out-in">
    <footer v-show="show">
      <b-row>
        <b-col>
          <router-link :to="{ name: 'Leaderboard' }" @mousedown="setTitle('Rangliste')">
            <TabbarButton icon="trophy" /> </router-link
        ></b-col>
        <b-col>
          <!-- :to="{ name: 'Gameplan', params: { month: 'februar' } }" -->
          <router-link :to="{ name: 'Gameplan' }" @mousedown="setTitle('Spielplan')">
            <TabbarButton icon="building-columns" />
          </router-link>
        </b-col>
        <!-- <b-col>
          <router-link :to="{ name: 'Statistik' }" @mousedown="setTitle('Statistik')">
            <TabbarButton icon="chart-pie" />
          </router-link>
        </b-col> -->
        <b-col>
          <router-link :to="{ name: 'Rules' }" @mousedown="setTitle('Regeln')">
            <TabbarButton icon="shield" />
          </router-link>
        </b-col>
      </b-row>
    </footer>
  </transition>
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue'
import TabbarButton from './TabbarButton.vue'
import store from '@/store'

export default {
  name: 'tab-bar',
  components: {
    TabbarButton
  },
  setup() {
    const state = reactive({
      show: store.getters.showTabbar
    })

    function setTitle(title) {
      store.dispatch('setTitle', title)
    }

    watchEffect(() => {
      state.show = store.getters.showTabbar
    })

    return {
      ...toRefs(state),
      setTitle
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  overflow: hidden;
}

indicator {
  display: block;
  width: 60px;
  height: 4px;
  border-radius: 5px;
  background: none;
}

svg {
  margin-top: 12px;
  font-size: 25px;
}

a {
  color: silver;
  text-decoration: none;
  display: inline-block;
  &.router-link-active {
    color: darkgoldenrod;

    indicator {
      display: block;
      background-color: darkgoldenrod;
    }
  }
}
</style>
